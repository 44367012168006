<template>
  <main class="main" role="main">
    <div class="page bg banner_padding">
      <div class="inner">
        <div class="page_con" id="page_con">
          <div class="cate_tab bg">
            <div class="inner category_inner">
              <div class="btn_wrap">
                <button
                  class="all"
                  :class="{ on: item.categoryID === onDepth2Item.categoryID }"
                  v-for="(item, index) in items"
                  :key="index"
                  @click="fnShowOn(index)"
                >
                  <img
                    v-if="
                      !$Util.isEmpty(item.categoryID) &&
                        !$Util.isEmpty(categories[item.categoryID].enabledImg)
                    "
                    :src="
                      require(`@/assets/static/images/${
                        categories[item.categoryID].enabledImg
                      }`)
                    "
                    alt=""
                  />
                  <span v-else>{{ item.categoryName }}</span>
                </button>
              </div>
              <div class="con_btn pd0" v-if="!$Util.isEmpty(userData)">
                <!-- <button class="btn bo">리스트 더보기</button> -->
                <button class="btn web_break" @click="fnWrite">
                  파티 생성하기</button
                ><!--버튼 중복: 웹 분기점 디자인 적용-->
              </div>
            </div>
          </div>
          <div
            class="party_list_wrap"
            v-on="
              pointer
                ? {
                    pointerdown: $event => start($event),
                    pointermove: $event => move($event),
                    pointerup: $event => end($event)
                  }
                : {
                    touchstart: $event => start($event),
                    touchmove: $event => move($event),
                    touchend: $event => end($event)
                  }
            "
          >
            <div class="list_top pc">
              <!-- PC -->
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right">
                <label class="checkbox" style="margin-right: 2rem">
                  <input
                    type="checkbox"
                    :true-value="'Y'"
                    :false-value="'N'"
                    :checked="endYn === 'Y'"
                    v-model="endYn"
                  />
                  <span class="label"><span>종료 비노출</span></span>
                </label>
                <button
                  class="li_btn col"
                  :class="{ on: rowType === 1 }"
                  @click="fnToggleView(1)"
                ></button
                ><!--'on'-->
                <button
                  class="li_btn row"
                  :class="{ on: rowType === 2 }"
                  @click="fnToggleView(2)"
                ></button>
                <label class="select">
                  <select v-model="sort">
                    <option :value="'REGDT_ASC'">신규등록순</option>
                    <option :value="'SHORT_DESC'">짧은기간순</option>
                    <option :value="'LONG_ASC'">긴기간순</option>
                    <option :value="'MODDT_ASC'">업데이트순</option>
                    <option :value="'AMT_DESC'">일 최저가순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="list_top mb">
              <!-- mobile -->
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :true-value="'Y'"
                    :false-value="'N'"
                    :checked="endYn === 'Y'"
                    v-model="endYn"
                  />
                  <span class="label"><span>종료 비노출</span></span>
                </label>
                <label class="select">
                  <select v-model="sort">
                    <option :value="'REGDT_ASC'">신규등록순</option>
                    <option :value="'SHORT_DESC'">짧은기간순</option>
                    <option :value="'LONG_ASC'">긴기간순</option>
                    <option :value="'MODDT_ASC'">업데이트순</option>
                    <option :value="'AMT_DESC'">일 최저가순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="list_top mb">
              <!-- mobile -->
              <div class="left"></div>
              <div class="right">
                <button
                  class="li_btn col"
                  :class="{ on: rowType === 1 }"
                  @click="fnToggleView(1)"
                ></button
                ><!--'on'-->
                <button
                  class="li_btn row"
                  :class="{ on: rowType === 2 }"
                  @click="fnToggleView(2)"
                ></button>
              </div>
            </div>
            <party-list
              :row-type="rowType"
              :items="list"
              :is-date="false"
              v-if="!$Util.isEmpty(list)"
              @reset="fnSearch"
            />
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>구독 검색 결과가 없습니다.</div>
              </div>
            </div>

            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
            <div class="con_btn" v-if="!$Util.isEmpty(userData)">
              <!-- <button class="btn bo">리스트 더보기</button> -->
              <button class="btn web_break" @click="fnWrite">
                파티 생성하기</button
              ><!--버튼 중복: 웹 분기점 디자인 적용-->
            </div>
          </div>
        </div>
        <div class="page_btn" v-if="!$Util.isEmpty(userData)">
          <div class="btn_wrap">
            <button class="btn" @click="fnWrite">
              파티 생성하기</button
            ><!--버튼 중복: 웹 분기점 디자인 적용-->
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice8'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
import PartyList from '@/components/views/party/list/index.vue'

export default {
  name: 'party-list-index',
  components: { PartyList },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
